<template>
	<div id="head" style="margin: 0;">
		<div style="padding:10px 15%;">
			<img src="../assets/images/logo2.png" style="vertical-align: middle;width:400px;">
			<div style="float:right;">
        <div style="display: inline-block">
          <p style="margin-top:25px;font-family:'Microsoft Yahei';font-size: 18px;color: #606266;font-weight: 550;">全国咨询热线</p>
          <p><span style="font-size: 25px;font-family:'Microsoft Yahei';position: relative;top:-10px;font-weight: 550;color:#0084C4">020-39392639</span></p>
        </div>
        <div style="display: inline-block;margin-left: 20px;">
          <img src="../assets/images/code.png" style="width: 65px;margin-left: 5px;">
          <p style="margin-top: -5px;font-size: 8px;margin-left: -2px;">扫码关注公众号</p>
        </div>
			</div>

		</div>
		<div style="text-align: center;background-color:#0084C4;height: 60px;display: flex;align-items: center;justify-content: center;width: 100%;">
			<el-menu style="display: inline-block;" class="el-menu-demo" mode="horizontal"  background-color="rgba(0,0,0,0)"
	text-color="#fff" :default-active="this.$route.path" router active-text-color="#fff">
				<el-menu-item index="/">首页</el-menu-item>
				<el-menu-item index="/tenderInfo">招标信息</el-menu-item>
				<el-submenu @click.native='goTo("/companyActive")' class="menuFirst"  index="/companyActive"
					:style="{backgroundColor:navColor}">
					<template slot="title">公司业务</template>
					<el-menu-item index="/tenderAgent" class="menuSecond">招标代理</el-menu-item>
					<el-menu-item index="/tenderSultation" class="menuSecond">招投标咨询</el-menu-item>
					<el-menu-item index="/tenderTrain" class="menuSecond">
						招投标培训
					</el-menu-item>
					<el-menu-item index="/tenderResearch" class="menuSecond">招投标专题研究</el-menu-item>
					<el-menu-item index="/tenderTotal" class="menuSecond">招标审计</el-menu-item>
				</el-submenu>
				<el-menu-item index="/police">政策法规</el-menu-item>
				<el-menu-item index="/news">新闻中心</el-menu-item>
				<el-menu-item index="/professor">专家中心</el-menu-item>
				<el-menu-item index="/aboutUs">关于我们</el-menu-item>
				<el-menu-item index="/member">人力资源</el-menu-item>
			</el-menu>
		</div>
		<el-carousel  :height="bannerHeight+'px'" :interval="5000">
			<el-carousel-item v-for="(item,index) in imgs" :key="index" >
				<img :class="{'hand-point': item.url != undefined && item.url !=''}" :src="handImgUrl(item)" style="width: 100%;" ref="bannerHeight" @load="imgload" @click="onRedirect(item)" />
			</el-carousel-item>
		</el-carousel>
	</div>
</template>
<script>
	export default {
		data(){
			return {
				bannerHeight:'',
				imgs:[],
				navColor:'#0084C4',
			}
		},
		methods: {
			goTo:function(res){
				this.$router.push(res);
			},

      //点击轮播图跳转
      onRedirect(item) {
        if (item.url != undefined && item.url !="") {
          window.open(item.url,'_self');
        }
      },

      handImgUrl(item) {
        let url = this.baseUrl2 + item.image_url;
        return url
      },
      imgload() {
        this.$nextTick(() => {
          this.bannerHeight = document.documentElement.clientWidth * 300 / 1152;
        })
      },
      getCarouselImages() {
        this.$axios.get(this.baseUrl + 'admin/Carouselctl/indexList').then(response => {
          if (response.data.code != '200') {
            console.error('轮播图加载失败')
          } else {
            console.log(response.data.data)
            this.imgs = response.data.data;
          }
        }).catch(function (error) {
          window.console.log(error)
        });

        this.imgload();
      },

		},
		mounted:function(){
      this.getCarouselImages()

			/*this.bus.$on('headerImg',data=>{
				// this.imgs=data;
			})*/
			window.addEventListener('resize',()=>{
				this.imgload();
			})
		},
		watch:{
			$route(to){
				if(to.path=='/companyActive' || to.path=='/tenderAgent' ||to.path=='/tenderSultation' ||to.path=='/tenderTrain' ||to.path=='/tenderResearch' || to.path=='/tenderTotal'){this.navColor='rgba(0,0,0,0.2)';}
				else{this.navColor="#0084C4"}
			}
		},
	}
</script>
<style>
	.el-menu--popup-bottom-start{margin-top: 0 !important;padding-top: 0 !important;}
</style>
<style scoped>
	.el-main{padding: 0 !important;}
	#head{width:100%;}
	#head .el-menu{padding:0;}
	#head .el-menu-item,#head /deep/ .el-submenu__title{
		font-size:18px !important;
		padding: 0 40px;
		border-bottom:none !important;
	}
	.el-menu--horizontal .menuSecond{
		background-color:rgba(0,0,0,0.8) !important;
		font-size: 16px;
		height: 50px !important;
		line-height: 50px !important;
	}
	.el-menu-item.is-active{
		background-color:rgba(0,0,0,0.2) !important;
	}
	.el-menu--horizontal .menuSecond{
		width:173px;
	}
	
	#head .el-menu-item:hover,#head  /deep/ .el-submenu__title:hover{
		background-color:rgba(0,0,0,0.2) !important;
	}
	.el-menu--horizontal .menuSecond:hover{
		background-color:#0084C4 !important;
	}
	#head .el-menu.el-menu--horizontal{
		border-bottom: none !important;
	}

  .hand-point{
    cursor: pointer;
  }
</style>
