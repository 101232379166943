<template>
  <div style="background-color:#0084C4;padding:40px 0 20px 0;font-size: 15px;margin: 0;width: 100%;">
    <div style="display: flex;text-align:left;color: #fff;margin-left: 50%;position: relative;left:-630px;width:1260px;">
      <div style="flex:0.12;"></div>
      <div style="flex:1;padding:0 1%;width: 300px;">
        <div style="margin-top: -20px;">
          <img src="../assets/images/研究院logo白底.png" style="width:70px;">
          <div style="display: inline-block;text-align: left;padding-left: 10px;">
            <p style="font-size: 25px;margin:12px 0 10px 0;border-bottom:#fff 2px solid;padding:3px 0;">招投研究院</p>
            <p style="margin-top: 0;">让招投标结果更完美</p>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <i class="iconfont icon-dianhua" style="font-size:40px;"></i>
          <span style="font-size: 28px;"> 020-39392639</span>
        </div>
      </div>
      <div style="flex:2;padding: 0 1%;">
        <div style="" class="sub-title"><span @click="goTo('/tenderInfo')">招标信息</span></div>
        <div class="sub-title"><span @click="goTo('/companyActive')">业务介绍</span></div>
        <div class="sub-title"><span @click="goTo('/professor')">专家中心</span></div>
        <div class="sub-title"><span @click="goTo('/aboutUs')">关于我们</span></div>
        <div class="sub-title"><span @click="goTo('/police')">政策法规</span></div>
        <div class="sub-title"><span @click="goTo('/news')">新闻中心</span></div>

        <div style="margin-top: 10px;margin-top: 50px;">总部：广州市南沙区进港大道12号中环广场A座2001</div>
        <!--			<p class="cont">谭经理：13926400466</p>-->
        <div style="margin-top: 10px;" class="cont">深圳分公司：广东省深圳市罗湖区西岭下北区11栋4楼A01</div>
      </div>
      <!--		<div style="flex:1;padding: 0 1%;">
            <p class="title"><span @click="goTo('/professor')">专家中心</span><span @click="goTo('/aboutUs')">关于我们</span></p>
          </div>
          <div style="flex:1;padding: 0 1%;">
            <p class="title"><span @click="goTo('/police')">政策法规</span><span @click="goTo('/news')">新闻中心</span></p>
          </div>-->
      <div style="flex:0.7;padding: 0 1%;">
        <img src="../assets/images/code.png" style="width: 130px;"/>
        <p class="cont">扫一扫关注公众号</p>
      </div>
    </div>
    <a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration: none;"><p style="text-align: center;color: #fff;">粤 ICP 备 19139672号-1</p></a>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {}
  },
  methods: {
    goTo: function (res) {
      this.$router.push(res)
    },
  },
}
</script>

<style scoped>
/*.title span{cursor: pointer;display: inline-block;width: 20%;font-size: 18px;}*/
.cont {
  margin: 0;
  padding: 0;
}

.sub-title {
  display: inline;
  margin-right: 30px;
  cursor: pointer;
  font-size: 18px;
}
</style>
