<template>
	<div style="display: inline-block;position:fixed;width:64px;top:50%;right:3px;z-index: 200;background-color: #fff;margin-top: -200px;">
<!--		<div v-show="activityLink" style="text-align: center;background-color:#0084C4;color: #fff;margin:0;padding: 10px 0;cursor: pointer;" @click="open()">
			<i class="el-icon-help" style="font-size: 25px;margin-bottom:5px;"></i>
			<p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">培训报名</p>
		</div>-->
		<div style="text-align: center;background-color: #0084C4;color: #fff;margin:1px 0;padding: 10px 0;cursor: pointer;" @click="goTo('/professor')">
			<i class="iconfont icon-zhuanjia1" style="font-size: 25px;"></i>
			<p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">专家入口</p>
		</div>
    <div style="text-align: center;background-color: #0084C4;color: #fff;margin:1px 0;padding: 10px 0;cursor: pointer;" @click="goToLinkSupplier()">
      <img src="../assets/images/supplier.svg" style="width: 25px;height: 25px;" />
      <p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">供应商入口</p>
    </div>
		<div style="text-align: center;background-color: #0084C4;color: #fff;margin:1px 0;padding: 10px 0;cursor: pointer;">
			<el-popover
				placement="left"
				width="200"
				trigger="hover"
				content="020-39392639">
				<div style="margin: 0;padding: 0;"  slot="reference">
					<i class="iconfont icon-dianhua" style="font-size: 25px;"></i>
					<p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">电话咨询</p>
				</div>
			</el-popover>
		</div>
		<div style="text-align: center;background-color: #0084C4;color: #fff;margin:1px 0;padding: 10px 0;cursor: pointer;">
			<el-popover
				placement="left"
				title="扫码关注公众号"
				trigger="hover">
				<img src="../assets/images/code.png" style="width: 150px;">
				<div style="margin: 0;padding: 0;"  slot="reference">
					<i class="iconfont icon-weixin" style="font-size: 25px;"></i>
					<p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">微信咨询</p>
				</div>
			</el-popover>
			
		</div>
		<div style="text-align: center;background-color: #0084C4;color: #fff;margin:1px 0;padding: 10px 0;cursor: pointer;" @click="goTo('/timeBeijing')">
			<i class="el-icon-watch" style="font-size: 25px;"></i>
			<p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">北京时间</p>
		</div>
		<div style="text-align: center;background-color: #0084C4;color: #fff;margin:0;padding: 10px 0;cursor: pointer;" @click="topFunction()">
			<i class="el-icon-upload2" style="font-size: 25px;"></i>
			<p style="margin: 0;transform:scale(0.8);font-size: 12px;letter-spacing:0.5px;">返回顶部</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tools',
		data() {return {
			activityLink:''
		}},
		mounted() {
			var that = this;
			this.$axios
				.post(this.baseUrl + "admin/tender/activityGet",{})
				.then((response) => {
					console.log(response);
					that.activityLink = response.data;
				})
				.catch(function(error) {
					window.console.log(error);
				}); //ajax请求失败
		},
		methods: {
			goTo(path){
				this.$router.push(path)
			},
			topFunction() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			open(){
				window.open(this.activityLink)
			},
      goToLinkSupplier() {
        window.open("https://zaoto.cn/admin")
      },
		},
	}
</script>

<style>
	div[role=tooltip]{font-size:18px;}
</style>
