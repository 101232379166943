<template>
  <div id="content">
    <div id="totalNum" style="display: flex;" class="sameWidth">
      <div style="flex: 1;">
        <h2>15年+</h2>
        <p>行业经验</p>
      </div>
      <div style="flex: 1;">
        <h2>20个+</h2>
        <p>涉及行业领域</p>
      </div>
      <div style="flex: 1;">
        <h2>100个+</h2>
        <p>涵盖省市</p>
      </div>
      <div style="flex: 1;">
        <h2>3000个+</h2>
        <p>业绩案例</p>
      </div>
    </div>
    <div style="margin 0;" id="noticeBanner">
      <div style="display: flex;" class="sameWidth">
        <div style="flex-grow: 1;width: 0;padding:0 25px 0 0;">
          <el-carousel indicator-position="outside" ref="carousel" :autoplay="true" height="300px"
                       :interval="3000">
            <el-carousel-item>
              <img src="@assets/images/homePage/公告/公告1.jpg" style="width:600px;height:300px;"/>
            </el-carousel-item>
            <el-carousel-item>
              <img src="@assets/images/homePage/公告/公告2.jpg" style="width:600px;height:300px;"/>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="flex-grow:1.5;width: 0;padding:0 10px 0 0;color: #595959;">
          <div id="tenderInfo">
            <el-input placeholder="请输入招标项目关键词" v-model="searchValue">
              <el-button slot="append" icon="el-icon-search" @click="goSearch()">搜索</el-button>
            </el-input>
          </div>
          <div style="margin: 20px 0 5px 0;">
            <p style="margin:0 50px 0 0;" :class="[showInfo=='tender'?'tabBarSel':'','tabBar']"
               @click="noticeChange('tender')">招标信息</p>
            <p style="margin:0;" :class="[showInfo!='tender'?'tabBarSel':'','tabBar']"
               @click="noticeChange('news')">新闻资讯</p>
            <p style="float: right;margin:10px 0 0 0;cursor: pointer;" @click="goTo()">查看更多></p>
          </div>
          <div id="noticeBannerContent" v-if="showInfo=='tender'">
            <div style="display:table-row;cursor: pointer;" v-for="(item,index) in tenderInfo" :key="index"
                 @click="goToContent(item)">
              <div class="contentInfo" style="display: table-cell;">({{ notice[item.notice - 1].name }}) {{ item.name }}</div>
              <div class="contentDate" style="display: table-cell;">{{ item.release_time.substr(0, 10) }}
              </div>
            </div>
          </div>
          <div id="noticeBannerContent" v-else>
            <div style="display:table-row;cursor: pointer;" v-for="(item,index) in newsInfo" :key="index"
                 @click="goToContent(item)">
              <div class="contentInfo" style="display: table-cell;">{{ item.news_name }}</div>
              <div class="contentDate" style="display: table-cell;">{{ item.release_time.substr(0, 10) }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div style="margin:100px 0;" class="sameWidth">
      <h1 style="margin-bottom: 50px;" class="font2">业务服务</h1>
      <h1 class="borderBottom"><span>&nbsp;</span></h1>
      <div style="display: flex;">
        <div style="flex: 1;padding:0 1%;" v-for="(item,index) in yewu.slice(0,3)" :key="index"
             class="hoverDiv">
          <div style="border:#EBEEF5 1px solid;border-radius: 5px;height: 100%;postition: realative;">
            <div style="background-color:#0084C4;color:#fff;padding:4% 0;">
              <p><i class="iconfont" v-bind:class="item.icon" style="font-size:70px;"></i></p>
              <h2>{{ item.title }}</h2>
            </div>
            <p style="padding: 20px;text-align: left;line-height:25px;font-size: 16px;color: #595959;">
              {{ item.des }}
            </p>
          </div>
        </div>
      </div>
      <div style="display: flex;margin-top:60px;">
        <div style="flex: 1;padding:0 1%;" v-for="(item,index) in yewu.slice(3,5)" :key="index"
             class="hoverDiv">
          <div style="border:#EBEEF5 1px solid;height: 100%;border-radius: 5px;postition: realative;">
            <div style="background-color:#0084C4;color:#fff;padding:4% 0;">
              <p><i class="iconfont" v-bind:class="item.icon" style="font-size:70px;"></i></p>
              <h2>{{ item.title }}</h2>
            </div>
            <p style="padding: 20px;text-align: left;line-height:25px;font-size: 16px;color: #595959;">
              {{ item.des }}
            </p>
          </div>
        </div>
        <div style="flex: 1;padding:0 1%;"></div>
      </div>
    </div>
    <div style="margin:0;padding:30px 0;box-sizing: border-box;">
      <h1 style="margin-bottom:50px;" class="font2">业务覆盖地区</h1>
      <h1 class="borderBottom"><span>&nbsp;</span></h1>
      <div style="box-sizing: border-box;padding:0;background-color: #F2F6FC;">
        <div style="display: inline-block;width:720px;vertical-align:middle;">
          <china-map></china-map>
        </div>
        <div style="display: inline-block;width:420px;vertical-align:middle;text-align: center;">
          <div style="display: flex;">
            <div
                style="flex:1;border-right:#DCDFE6 1px solid;border-bottom:#DCDFE6 1px solid;padding-bottom:30px;">
              <h1 style="color:#FB8005;">15年+</h1>
              <h3>行业经验</h3>
            </div>
            <div style="flex:1;border-bottom:#DCDFE6 1px solid;padding-bottom:30px;">
              <h1 style="color:#FB8005;">20个+</h1>
              <h3>涉及行业领域</h3>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex:1;border-right:#DCDFE6 1px solid;padding-top:30px;">
              <h1 style="color:#FB8005;">100个+</h1>
              <h3>涵盖省市</h3>
            </div>
            <div style="flex:1;padding-top:30px;">
              <h1 style="color:#FB8005;">3000个+</h1>
              <h3>业绩案例</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin:100px 0 50px 0;" class="sameWidth">
      <h1 style="margin-bottom:50px;" class="font2">客户案例</h1>
      <h1 class="borderBottom"><span>&nbsp;</span></h1>
      <div style="display: flex;" class="anli">
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/南方电网.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/中国电气装备集团.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/保碧新能源.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/中通服.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/南沙科金控股.png">
          </div>
        </div>
      </div>

      <div style="display: flex;" class="anli">
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州南沙经济开发区商务局.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/中共广州市南沙区委员会组织部.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州市规划和自然局南沙分局.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州公安局南沙分局.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/南沙消防救援大队.png">
          </div>
        </div>
      </div>

      <div style="display: flex;" class="anli">
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/华南理工大学.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州市海珠区人才服务管理办公室.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州市白云区城市管理和综合执法局.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/佛山顺德公安局.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/顺德农业农村局.png">
          </div>
        </div>
      </div>

      <div style="display: flex;" class="anli">
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州邮政局.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/汇源通.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/南方投资.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/广州天河骏景花园.png">
          </div>
        </div>
        <div style="flex: 0.1;"></div>
        <div style="flex: 1;" class="imgDiv">
          <div>
            <img src="@assets/images/homePage/珠江帝景.png">
          </div>
        </div>
      </div>

    </div>

    <!--友情链接-->
    <!--友情链接-->
    <div class="ref-link-container sameWidth">
      <h1 class="ref-title font2" style="margin-left: -100px;">友情链接</h1>
      <div>
        <div class="ref-item" v-for="(item,idx) in linkData" @click="goToPage(item.url)" v-bind:key="idx">
          <img class="ref-img" :src="item.imgUrl" :alt="item.name">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ref-link-container {
  margin-top: 60px;
  width: 1280px;
  text-align: left;
}

.ref-title {
  //color: #0E5A69;
  //font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 40px;
}

.ref-item {
  cursor: pointer;
//width: 222px; //height: 62px; display: inline-block;
  padding: 12px 10px 9px 10px;
  //border: solid 1px #CBCBCB;
  border: #E4E7ED 1px solid;
  box-shadow: 0 0 12px #EBEEF5;
  margin-right: 14px;
  margin-bottom: 20px;
}

.ref-img {
  width: 197px;
  height: 50px;
}


#content {
  text-align: center;
  padding: 0;
}

.font2 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff));
  color: transparent;
  -webkit-background-clip: text;
}

.borderBottom {
  margin-top: -70px;
}

.borderBottom span {
  border-bottom: #FF8700 3px solid;
  display: inline-block;
  width: 50px;
}

/*统计模块*/
#totalNum {
  text-align: center;
  margin: 20px 10%;
}

#totalNum h2 {
  color: #FB8005;
}


.sameWidth .hoverDiv {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
}

.sameWidth .hoverDiv:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
}

/*公告模块*/
#noticeBanner {
  padding: 30px 0;
  background-color: #F2F6FC;
  text-align: left;
}

#noticeBannerContent {
  display: table;
  width: 100%;
  table-layout: fixed;
}

#noticeBannerContent > div {
  height: 35px;
  line-height: 35px;
}

#noticeBannerContent > div:hover {
  color: #11AEE8;
}

#noticeBanner .contentInfo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}

#noticeBanner .contentDate {
  text-align: right;
  width: 100px;
}

#noticeBanner .tabBar {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  font-family: inherit;
  border-bottom: #F2F6FC 2px solid;
  cursor: pointer;
}

#noticeBanner .tabBarSel {
  color: #11AEE8;
  border-bottom: #11AEE8 2px solid !important;
}

.anli {
  margin-bottom: 20px;
}

.anli .imgDiv {
  border-radius: 2px;
  border: #E4E7ED 1px solid;
  box-shadow: 0 0 12px #EBEEF5;
  height: 150px;
  line-height: 150px;
}

.imgDiv div {
  padding: 0 5%;
}

.imgDiv img {
  width: 100%;
  vertical-align: middle;
}


#tenderInfo /deep/ .el-input__inner {
  border: #C0C4CC 1px solid;
}

#tenderInfo /deep/ .el-button {
  border: #C0C4CC 1px solid;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<script>
import chinaMap from "@components/chinamap.vue";

export default {
  name: 'fontbody',
  components: {
    chinaMap,
  },
  data() {
    return {
      linkData: [
        {name: '中国政府采购网', url: 'http://www.ccgp.gov.cn/ ', imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E9%87%87%E8%B4%AD%E7%BD%91.png'},
        {name: '中国招投标协会', url: 'http://www.ctba.org.cn/ ', imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E4%B8%AD%E5%9B%BD%E6%8B%9B%E6%8A%95%E6%A0%87%E5%8D%8F%E4%BC%9A.png'},
        {
          name: '中国招投标公共服务平台',
          url: 'http://www.cebpubservice.com',
          imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E4%B8%AD%E5%9B%BD%E6%8B%9B%E6%8A%95%E6%A0%87%E5%85%AC%E5%85%B1%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0.png'
        },
        {
          name: '广东省公共资源交易联合会',
          url: 'https://www.gdprea.com',
          imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E5%B9%BF%E4%B8%9C%E7%9C%81%E5%85%AC%E5%85%B1%E8%B5%84%E6%BA%90%E4%BA%A4%E6%98%93%E8%81%94%E5%90%88%E4%BC%9A.png'
        },
        {
          name: '广东省政府采购协会',
          url: 'http://www.gdgpa.org.cn',
          imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E5%B9%BF%E4%B8%9C%E7%9C%81%E6%94%BF%E5%BA%9C%E9%87%87%E8%B4%AD%E5%8D%8F%E4%BC%9A.png'
        },
        {
          name: '广东省招投标协会',
          url: 'http://www.gtba.org.cn',
          imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E5%B9%BF%E4%B8%9C%E7%9C%81%E6%8B%9B%E6%8A%95%E6%A0%87%E5%8D%8F%E4%BC%9A.png'
        },
        {
          name: '广州公共资源交易中心',
          url: 'http://www.gzggzy.cn',
          imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E5%B9%BF%E5%B7%9E%E5%85%AC%E5%85%B1%E8%B5%84%E6%BA%90%E4%BA%A4%E6%98%93%E4%B8%AD%E5%BF%83.png'
        },
        {
          name: '阳光采购服务平台',
          url: 'http://cg.gemas.com.cn',
          imgUrl: 'https://zaotocn.oss-cn-guangzhou.aliyuncs.com/img/link/%E9%98%B3%E5%85%89%E9%87%87%E8%B4%AD%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0.png'
        },
      ],

      yewu: [{
        icon: 'icon-zhaobiao',
        title: '招标代理',
        des: '核心团队具有十余年招标代理经验，为企事业单位提供制订方案、制订招标文件及发布公告、配合澄清答疑、组织收开评标、发布中标公示和公告等完整招标服务流程。'
      },
        {
          icon: 'icon-zixun1',
          title: '招投标咨询',
          des: '招投标咨询服务是为企事业单位提供招标咨询、投标咨询服务，是公司高端服务、高端智库形象塑造的核心业务，其中在招标管理、投标管理、招投标系统开发咨询方面极具竞争优势。'
        },
        {
          icon: 'icon-peixun',
          title: '招投标培训',
          des: '招投标培训服务是为企事业单位提供各种招标投标培训课程，旨在帮助客户规范招投标管理、降低招投标风险、提高招投标效率、提高成功概率。培训服务分为公益课、公开课、企业内训课。'
        },
        {
          icon: 'icon-audit',
          title: '招标审计',
          des: '为客户提供招投标制度和流程规范化审查；招标文件和投标文件规范性审查；招投标实施流程规范化审核；审计意见整改等服务。'
        },
        {
          icon: 'icon-yanjiu',
          title: '招投标专题研究',
          des: '招投研究院（广州）有限公司在智能评标系统、招标人/投标人画像、招投标管理系统等专题领域进行研发，为企事业提供全方位的招投标服务解决方案。'
        },
      ],
      showInfo: 'tender',
      tenderInfo: [],
      newsInfo: [],
      searchValue: ''
    }
  },
  methods: {
    goToPage(url) {
      window.open(url,'_blank')
    },
    noticeChange(res) {
      this.showInfo = res;
    },
    goTo() {
      if (this.showInfo == 'tender') {
        this.$router.push('/tenderInfo')
      } else {
        this.$router.push('/news')
      }
    },
    goToContent(val) {
      if (this.showInfo == 'tender') {
        this.$router.push({
          path: '/tenderInfoContent',
          query: {
            id: window.btoa(window.btoa(val.tender_info_id))
          }
        })
      } else {
        this.$router.push({
          path: '/newsContent',
          query: {
            id: val.news_id
          }
        })
      }
    },
    goSearch() {
      this.$router.push({
        path: '/tenderInfo',
        query: {
          val: this.searchValue
        }
      })
    }
  },
  watch: {
    $route(to) {
      if (to.path == '/') {
        this.bus.$emit('headerImg', this.headerImg);
      }
    }
  },
  mounted: function () {
    var that = this;
    this.bus.$emit('headerImg', this.headerImg);

    this.$axios.post(this.baseUrl + 'admin/tender/searchTender?page=1&limit=5&state=1').then(response => {
      if (response.data.code != '200') {
        console.log(response.data.msg);
      } else {
        that.tenderInfo = response.data.data.res
      }
    }).catch(function (error) {
      window.console.log(error)
    }); //ajax请求失败
    this.$axios.post(this.baseUrl + 'admin/article/searchArticle?page=1&limit=5&state=1').then(response => {
      if (response.data.code != '200') {
        console.log(response.data.msg);
      } else {
        that.newsInfo = response.data.data.res
      }
    }).catch(function (error) {
      window.console.log(error)
    }); //ajax请求失败
  }
};
</script>
