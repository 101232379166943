<template>
	<div style="min-width:1260px;padding: 0;">
		<my-header></my-header>
		<fontbody v-show="urlPath == '/'"></fontbody>
		<router-view v-show="urlPath != '/'"></router-view>
		<my-footer></my-footer>
		<tools></tools>
	</div>
</template>
<script>
	import myHeader from "@components/header";
	import myFooter from "@components/footer";
	import tools from "@components/tools";
	import fontbody from "@components/frontbody.vue";
	export default {
		name: 'index',
		components: {
			myHeader,
			myFooter,
			tools,
			fontbody
		},
		data() {
			return {
				urlPath: '',
			}
		},
		beforeMount() {
			if (window.navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)) {
				this.$router.push('/mobile');
			} else {
				//this.$router.push('/');
			}
		},
		created() {
			this.urlPath = this.$route.path;
		},
		watch: {
			$route(to) {
				this.urlPath = to.path;
			}
		},
	}
</script>

<style>
</style>
